var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contents-area home-contents"},[_c('div',{staticClass:"section first-screen"},[_c('video',{attrs:{"width":"100%","height":"100%","preload":"auto","autoplay":"true","loop":"true","muted":"muted","poster":'//' + _vm.serverLink + _vm.listSettings.mainSettings.headerpic},domProps:{"muted":true}},[_c('source',{attrs:{"src":'//' + _vm.serverLink + _vm.listSettings.mainSettings.video,"type":"video/mp4"}})]),_c('div',{staticClass:"video-background"}),_c('div',{staticClass:"container"},[_c('h1',{domProps:{"innerHTML":_vm._s(
          _vm.curCountry
            ? _vm.curCountry.title
              ? _vm.translations('CompanyCheckH1WTitle', [_vm.curCountry.title])
              : _vm.translations('CompanyCheckH1In', [
                  _vm.curCountry.title
                    ? _vm.curCountry.title
                    : _vm.curCountry.cases.prepositional ])
            : _vm.translations('CompanyCheck')
        )}}),_c('CountrySelect'),_c('div',{staticClass:"triggers-first"},[_c('div',{staticClass:"trigger"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.listSettings.mainSettings.trigger1_title)+" ")]),_c('div',{staticClass:"subtitle"},[_c('router-link',{attrs:{"to":"/foreign-registers"}},[_vm._v(_vm._s(_vm.listSettings.mainSettings.trigger1_subtitle))])],1)]),_c('div',{staticClass:"trigger"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.listSettings.mainSettings.trigger2_title)+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.listSettings.mainSettings.trigger2_subtitle)+" ")])]),_c('div',{staticClass:"trigger"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.listSettings.mainSettings.trigger3_title)+" ")]),_c('div',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.listSettings.mainSettings.trigger3_subtitle)+" ")])])])],1)]),_c('div',{staticClass:"section first-data-section"},[_c('div',{staticClass:"container"},[(_vm.isCountriesLoaded)?_c('h2',{staticClass:"country-h2",domProps:{"innerHTML":_vm._s(
          _vm.curCountry
            ? _vm.curCountry.title2
              ? _vm.curCountry.title2
              : _vm.translations('CompanyCheckIn', [_vm.curCountry.cases.genitive])
            : _vm.translations('CompanyCheck')
        )}}):_vm._e(),_c('div',{staticClass:"first-data-cont"},[_c('div',{staticClass:"advantages-cont"},[_c('FirstAdvantagesList')],1),_c('div',{staticClass:"doc-exapmles"},[_c('DocsList',{attrs:{"countryId":_vm.curCountryId}})],1)])])]),_c('ReviewsSlider'),(_vm.curCountry.short_description)?_c('div',{staticClass:"section seo anons"},[_c('div',{staticClass:"container"},[_c('RichTextContents',{attrs:{"containerClass":"anons","text":_vm.curCountry.short_description}})],1)]):_vm._e(),_c('FaqAccordeon',{attrs:{"title":true}}),(_vm.curCountry.description)?_c('div',{staticClass:"section seo"},[_c('div',{staticClass:"container"},[_c('RichTextContents',{attrs:{"text":_vm.curCountry.description}})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }