<template>
  <div class="contents-area home-contents">
    <div class="section first-screen">
      <video
        width="100%"
        height="100%"
        preload="auto"
        autoplay="true"
        loop="true"
        muted="muted"
        :poster="'//' + serverLink + listSettings.mainSettings.headerpic"
      >
        <source
          :src="'//' + serverLink + listSettings.mainSettings.video"
          type="video/mp4"
        />
      </video>
      <div class="video-background"></div>
      <div class="container">
        <h1
          v-html="
            curCountry
              ? curCountry.title
                ? translations('CompanyCheckH1WTitle', [curCountry.title])
                : translations('CompanyCheckH1In', [
                    curCountry.title
                      ? curCountry.title
                      : curCountry.cases.prepositional,
                  ])
              : translations('CompanyCheck')
          "
        ></h1>
        <CountrySelect />
        <div class="triggers-first">
          <div class="trigger">
            <div class="title">
              {{ listSettings.mainSettings.trigger1_title }}
            </div>
            <div class="subtitle">
              <router-link to="/foreign-registers">{{
                listSettings.mainSettings.trigger1_subtitle
              }}</router-link>
            </div>
          </div>
          <div class="trigger">
            <div class="title">
              {{ listSettings.mainSettings.trigger2_title }}
            </div>
            <div class="subtitle">
              {{ listSettings.mainSettings.trigger2_subtitle }}
            </div>
          </div>
          <div class="trigger">
            <div class="title">
              {{ listSettings.mainSettings.trigger3_title }}
            </div>
            <div class="subtitle">
              {{ listSettings.mainSettings.trigger3_subtitle }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section first-data-section">
      <div class="container">
        <h2
          v-if="isCountriesLoaded"
          class="country-h2"
          v-html="
            curCountry
              ? curCountry.title2
                ? curCountry.title2
                : translations('CompanyCheckIn', [curCountry.cases.genitive])
              : translations('CompanyCheck')
          "
        ></h2>
        <div class="first-data-cont">
          <div class="advantages-cont">
            <FirstAdvantagesList />
          </div>
          <div class="doc-exapmles">
            <DocsList :countryId="curCountryId" />
          </div>
        </div>
      </div>
    </div>
    <ReviewsSlider />
    <div class="section seo anons" v-if="curCountry.short_description">
      <div class="container">
        <RichTextContents
          containerClass="anons"
          :text="curCountry.short_description"
        />
      </div>
    </div>
    <FaqAccordeon :title="true" />
    <div class="section seo" v-if="curCountry.description">
      <div class="container">
        <RichTextContents :text="curCountry.description" />
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL, metrikaCode } from "@/config";
import CountrySelect from "@/components/CountrySelect";
import DocsList from "@/components/egrul/DocsList";
import FirstAdvantagesList from "@/components/app/FirstAdvantagesList";
import ReviewsSlider from "@/components/egrul/ReviewsSlider";
//import BottomVideo from "@/components/egrul/BottomVideo";
import FaqAccordeon from "@/components/app/FaqAccordeon";
import RichTextContents from "@/components/RichTextContents";
import { mapGetters, mapActions } from "vuex";

// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";

export default {
  name: "Country",
  metaInfo() {
    return {
      title:
        this.listSettings.mainSettings.title + " | " + this.listLang.site_name,
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            this.listSettings.mainSettings.meta_desc +
            " - " +
            this.curCountry.meta_desc,
        },
        {
          name: "keywords",
          content:
            this.listSettings.mainSettings.meta_keywords +
            " - " +
            this.curCountry.meta_kwords,
        },
      ],
    };
  },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      showModal: false,
      goalReached: false,
    };
  },
  components: {
    CountrySelect,
    DocsList,
    FirstAdvantagesList,
    ReviewsSlider,
    FaqAccordeon,
    RichTextContents,
  },
  computed: {
    ...mapGetters([
      "listSettings",
      "listLang",
      "translations",
      "isCountriesLoaded",
      "curCountryId",
      "curCountry",
      "ListLoggedUser",
    ]),
  },
  methods: {
    ...mapActions(["fetchCountries", "setCurPageCountry", "refreshSettings"]),
    reachPageGoal() {
      if (this.ListLoggedUser.boughts === 0) {
        if (!this.goalReached) {
          this.$metrika.reachGoal("goalPagesVisit", {
            page: "Страница страны",
            countryCode: this.curCountry.code,
            country: this.curCountry.text,
          });
          this.goalReached = true;
        }
      }
    },
  },
  async created() {
    if (!this.isCountriesLoaded && !this.isCountriesLoading) {
      await this.fetchCountries({
        lang: this.$cookies.get("user_language"),
        country: this.$route.path,
      });
    } else {
      console.log("setting page country");
      await this.setCurPageCountry(this.$route.path);
    }
  },
  mounted() {
    document.addEventListener("yacounter" + metrikaCode + "inited", () => {
      this.reachPageGoal();
    });
    if (this.$metrika) {
      this.reachPageGoal();
    }
  },
};
</script>